import React from 'react'


export const CreateAIPage = () => {
    return (
        <div className="flex">
            <div className="flex-1 p-4">
                <h1 className="text-4xl font-bold">COMING SOON</h1>
            </div>
        </div>
      )
    }


export default CreateAIPage